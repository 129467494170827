export const rulesForPhone = [
  (value) => !!value || "必须",
  (value) => (value || "").length != 10 || "请输入11位手机号",
  (value) => {
    const pattern = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/
    return pattern.test(value) || "无效的手机号"
  },
]

export const rulesForPassWord = [
  (value) => !!value || "必须",
  (value) => (value || "").length >= 8 || "密码至少8个字符",
  (value) => {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\S])[a-zA-Z0-9\S]{8,16}$/
    return pattern.test(value) || "密码至少一个大写、一个小写字母、一个数字,且长度为8到16位"
  }
]
