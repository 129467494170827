<template>
  <div>
    <TopBar title="个人信息">
      <BackIcon></BackIcon>
    </TopBar>
    <v-card elevation="0">
      <ProfileItem ref="avatar" desc="头像" title="设置头像" :img="avatarImgPath" @submit="avatarSub"></ProfileItem>
      <ProfileItem ref="nickname" desc="昵称" title="设置昵称" :data="nickname" @submit="nicknameSub"></ProfileItem>
      <v-divider></v-divider>
      <ProfileItem ref="phone" desc="手机号" title="设置手机号" :data="phone" :rules="rules" @submit="phoneSub"></ProfileItem>
    </v-card>
  </div>
</template>

<script>
import TopBar from 'components/common/TopBar'
import BackIcon from 'components/common/BackIcon'
import ProfileItem from '../dialogs/ProfileItem'
import { postProfile } from 'network/user'
import { rulesForPhone } from 'common/const'
import { mapState } from 'vuex'
export default {
  name: 'UpdateProfile',
  components: {
    TopBar,
    BackIcon,
    ProfileItem
  },
  data: () => ({
    rules: rulesForPhone,
    avatarImgPath: require('../../../assets/img/account.png')
  }),
  methods: {
    avatarSub() {
      console.log('avatarSub')
    },
    nicknameSub(nickname) {
      this.reqPostProfile(nickname, this.phone, () => {
        this.$notify({title: '设置成功',type: 'success',duration: 1500})
        this.cancelState('nickname')
      })
    },
    phoneSub(phone) {
      const res = rulesForPhone[2](phone)
      if (res === true) {
        this.reqPostProfile(this.nickname, phone, () => {
          this.cancelState('phone')
          this.$notify({title: '设置成功', message: '请重新登录', type: 'success',duration: 1500})
          this.$store.commit('clearToken')
          this.$router.push('/login')
        })
      } else {
        this.$notify({title: res, type: 'error', duration: 1500})
      }
    },
    reqPostProfile(nickname, phone, callback = () => {}) {
      postProfile(nickname, phone).then(res => {
        this.$store.dispatch('getUserInfo')
        callback()
      })
    },
    cancelState(ref) {
      this.$refs[ref].dialog = false
      this.$refs[ref].loading = false
    }
  },
  computed: {
    ...mapState({
      nickname: state => state.userInfo.nickname,
      phone: state => state.userInfo.phone
    })
  }
}
</script>

<style>

</style>