<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-content>
          <v-list-item-title>{{desc}}</v-list-item-title>
        </v-list-item-content>
        <span v-if="data" class="grey--text text--darken-2">{{ data }}</span>
        <v-avatar v-if="img" rounded>
          <v-img :src="img" color="white"></v-img>
        </v-avatar>
        <v-list-item-icon>
          <v-icon class="grey--text text--darken-2">mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>
    <!-- 对话框内容 -->
    <v-card elevation="0" class="v-app-bar v-toolbar v-sheet">
      <TopBar :title="title">
        <v-btn text @click="cancel">取消</v-btn>
        <v-btn text @click="submit" slot="right" :disabled="disabled" :loading="loading">确定</v-btn>
      </TopBar>
      <v-text-field 
      v-if="data"
      solo flat color="blue-gray"
      v-model="info"
      clearable
      clear-icon="mdi-close-circle"
      @click:clear="clearInputVal"
      :rules="rules"
      >
      </v-text-field>
      <v-file-input v-if="img" show-size prepend-inner-icon="mdi-camera" 
      placeholder="选择照片" v-model="files"
      color="blue-gray"
      solo flat prepend-icon=""
      @change="fileChange"
      >
      </v-file-input>
    </v-card>
  </v-dialog>
</template>

<script>
import TopBar from 'components/common/TopBar'
import BackIcon from 'components/common/BackIcon'
export default {
  name: "ProfileItem",
  components: {
    TopBar,
    BackIcon
  },
  props: {
    desc: String,
    title: String,
    data: String,
    img: String,
    rules: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    info: '',
    files: null
  }),
  created() {
    this.info = this.data
  },
  methods: {
    clearInputVal() {
      this.info = ''
    },
    submit() {
      this.loading = true
      if (!this.info) {
        this.$notify({title: '请输入内容',type: 'error',duration: 1500})
        this.loading = false
      } else {
        this.$emit('submit', this.info)
      }
    },
    cancel() {
      this.dialog = false
      this.info = this.data
    },
    fileChange(file) {
      // console.log('fileChange', file)
    }
  },
  computed: {
    disabled() {
      return this.info === this.data || !this.info
    }
  }
}
</script>

<style>
</style>